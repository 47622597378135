import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { loader } from 'helpers/generic';
import { default as ClockingsService } from 'services/Clockings';
import CurrentTime from 'components/CurrentTime';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;

	#btn-close {
		margin-top: 20px;
		width: 100px;
	}
`;

const WhiteSquare = styled.div`
	background: white;
	color: #333;
	padding: 10px;
	border-radius: 15px;
	width: 85%;
	margin: 0 auto;

	.dispenser {
		display: flex;
		align-items: center;
		justify-content: start;

		.icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: 40px;
			min-height: 40px;
			aspect-ratio: 1;
			border-radius: 50%;
			background-color: lightgray;
			margin-right: 10px;

			i {
				color: white;
				font-size: 30px;
			}
		}

		.name {
			width: 100%;
			text-transform: uppercase;
		}
	}

	.schedule-title {
		text-align: center;
		font-size: 20px;
		margin-top: 15px;
		color: var(--plenoil-blue);
	}

	.schedule-schedule {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--plenoil-blue);
		margin-top: 10px;
		padding-bottom: 5px;
		font-size: 13px;

		.date-wrapper {
			i {
				margin-right: 5px;
			}
		}

		.time-wrapper {
			i {
				margin-right: 5px;
			}
		}
	}

	.schedule-data {
		padding: 20px 10px;
		border-bottom: 1px solid var(--plenoil-blue);

		.schedule-data-row {
			display: flex;
			margin-top: 5px;

			span {
				text-align: right;
				text-transform: uppercase;
				width: 70px;
			}

			i {
				margin: 0 5px;
			}
		}
		
		button {
			display: flex;
			margin: 0 auto;
			margin-top: 20px;
		}
	}

	.start-schedule {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;
		border: 2px solid var(--plenoil-blue);
		border-radius: 15px;
		margin-top: 20px;
		color: var(--plenoil-blue);

		div {
			margin-bottom: 5px;
			font-size: 18px;
		}

		span {
			font-size: 15px;
		}
	}

	.next-schedule {
		padding-bottom: 5px;

		.next-schedule-title {
			text-align: center;
			font-size: 16px;
			margin-top: 15px;
			color: var(--plenoil-blue);
		}

		.not-available {
			font-size: 14px;
			text-align: center;
		}

		.schedule-schedule {
			padding-bottom: 10px;
		}
	}

	#historial-btn {
		color: var(--plenoil-blue);
		text-transform: uppercase;
		display: flex;
		margin: 0 auto;
		font-size: 14px;
		margin-top: 20px;
		font-weight: bold;
	}
`;

export default function () {
	let navigate = useNavigate();

	const checkIntervalRef = useRef(null);

	let [forceReload, setForceReload] = useState(null);
	let [loading, setLoading] = useState(false);
	let [dispenser, setDispenser] = useState(null);
	let [schedule, setSchedule] = useState(null);
	let [nextSchedule, setNextSchedule] = useState(null);

	useEffect(() => {
		let dispenser = window.sessionStorage.getItem('dispenser');
		if ( dispenser ) dispenser = JSON.parse(dispenser);
		if ( !dispenser ) {
			navigate('/clockings/list');
		} else {
			setDispenser(dispenser);
		}
	}, [navigate])

	useEffect(() => {
		if ( !dispenser ) return;

		const getData = async (showLoader = true) => {
			if (showLoader) setLoading(true);

			// Get current schedule
			let schedule = await ClockingsService.getDispenserCurrentSchedule(dispenser.id);
			setSchedule(schedule);

			// Get next schedule
			let nextSchedule = await ClockingsService.getDispenserNextSchedule(dispenser.id);
			setNextSchedule(nextSchedule);

			if (showLoader) setLoading(false);
		};
		getData();

		checkIntervalRef.current = setInterval(() => {
			getData(false);
		}, 5000);

		return function cleanup() {
			clearInterval(checkIntervalRef.current);
			ClockingsService.cancel();
		}
	}, [dispenser, forceReload]);

	const _setDispenserActive = () => {
		let dispensers = window.sessionStorage.getItem('dispensers');
		if ( dispensers ) {
			dispensers = JSON.parse(dispensers);
		} else {
			dispensers = [];
		}
		dispensers.push(dispenser);
		window.sessionStorage.setItem('dispensers', JSON.stringify(dispensers));
	
		// trigger event reload-active-dispensers
		let event = new CustomEvent('reload-active-dispensers');
		document.dispatchEvent(event);
	}

	const _setDispenserInactive = () => {
		let dispensers = window.sessionStorage.getItem('dispensers');
		if ( dispensers ) {
			dispensers = JSON.parse(dispensers);
			dispensers = dispensers.filter(d => d.id !== dispenser.id);
			window.sessionStorage.setItem('dispensers', JSON.stringify(dispensers));
		}

		// trigger event reload-active-dispensers
		let event = new CustomEvent('reload-active-dispensers');
		document.dispatchEvent(event);
	}

	const startChronometer = async () => {
		let result = await ClockingsService.startDispenserChronometer(dispenser.id);
		if ( result.status ) {
			_setDispenserActive();
		}
		setForceReload(Math.random());
	}

	const stopChronometer = async () => {
		let result = await ClockingsService.stopDispenserChronometer(dispenser.id);
		if ( result.status ) {
			_setDispenserInactive();
		}
		setForceReload(Math.random());
	}

	const closeFix = () => {
		navigate('/clockings/list', { replace: true });
	}

	return (
		<>
			<LoggedLayout 
				hideFooter={true}
			>
				<Wrapper>
					{ loading && loader }
					
					{ !loading &&
						<WhiteSquare id="white-square">
							{ schedule &&
								<>
									{ schedule.action === 'start' && 
										<div>
											<div className="dispenser">
												<span className="icon">
													<i className="bi bi-person"></i>
												</span>
												<div className="name">
													{dispenser?.full_name}
												</div>
											</div>

											<div className="schedule-title">
												{schedule.name}
											</div>

											<div className="schedule-data">
												<div className="schedule-data-row">
													<span></span> <i className="bi bi-calendar"></i> {moment(schedule.day).format('DD/MM/YYYY')}
												</div>
												<div className="schedule-data-row">
													<span>ENTRADA</span> <i className="bi bi-clock"></i> {moment('2024-11-29 ' + schedule.start_at).format('HH:mm')}
												</div>
												<div className="schedule-data-row">
													<span>SALIDA</span> <i className="bi bi-clock"></i> {moment('2024-11-29 ' + schedule.end_at).format('HH:mm')}
												</div>
											</div>

											{ schedule.can_enter &&
												<div className="start-schedule">
													<div>COMIENZO DE TURNO</div>
													<span><CurrentTime hideSeconds={true} /></span>
													<button className="btn btn-sm btn-plenoil-primary" onClick={startChronometer}>REGISTRAR ENTRADA</button>
												</div>
											}
										</div>
									}
									{ schedule.action === 'end' && 
										<div>
											<div className="dispenser">
												<span className="icon">
													<i className="bi bi-person"></i>
												</span>
												<div className="name">
													{dispenser?.full_name}
												</div>
											</div>

											<div className="schedule-title">
												{schedule.name}
											</div>

											<div className="schedule-schedule">
												<div className="date-wrapper">
													<i className="bi bi-calendar"></i>
													{moment(schedule.day).format('DD/MM/YYYY')}
												</div>

												<div className="time-wrapper">
													<i className="bi bi-clock"></i>
													{moment('2024-11-29 ' + schedule.start_at).format('HH:mm')} - {moment('2024-11-29 ' + schedule.end_at).format('HH:mm')}
												</div>
											</div>

											<div className="schedule-data">
												<div className="schedule-data-row">
													<span>ENTRADA</span> <i className="bi bi-clock"></i> {moment('2024-11-29 ' + schedule.chronometer_start_at).format('HH:mm')}
												</div>
												<div className="schedule-data-row">
													<span>SALIDA</span> <i className="bi bi-clock"></i> 
													{/* { schedule.chronometer_end_at && */}
														{/* <>{moment('2024-11-29 ' + schedule.chronometer_end_at).format('HH:mm')}</> */}
													{/* } */}
												</div>

												<button className="btn btn-sm btn-plenoil-orange" onClick={stopChronometer}>REGISTRAR SALIDA</button>
											</div>

										</div>
									}
								</>
							}

							<div className="next-schedule">
								<div className="next-schedule-title">
									PRÓXIMO TURNO
								</div>
								{ nextSchedule &&
									<div className="schedule-schedule">
										<div className="date-wrapper">
											<i className="bi bi-calendar"></i>
											{moment(nextSchedule.day).format('DD/MM/YYYY')}
										</div>

										<div className="time-wrapper">
											<i className="bi bi-clock"></i>
											{moment('2024-11-29 ' + nextSchedule.start_at).format('HH:mm')} - {moment('2024-11-29 ' + nextSchedule.end_at).format('HH:mm')}
										</div>
									</div>
								}
								{ !nextSchedule && <div className="not-available">No hay turno disponible</div> }
							</div>

							<button id="historial-btn" className="btn btn-sm btn-link" onClick={() => navigate('/clockings/historial/' + dispenser?.id)}>Histórico</button>
						</WhiteSquare>
					}

					<button id="btn-close" className="btn btn-sm btn-plenoil-primary" onClick={() => closeFix()}>Cerrar</button>
				</Wrapper>
			</LoggedLayout>
		</>
	);
}


