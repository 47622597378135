import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { loader } from 'helpers/generic';
import { debug } from 'helpers/app';
import { default as ClockingsService } from 'services/Clockings';
import CurrentTime from 'components/CurrentTime';
import CurrentDate from 'components/CurrentDate';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
`;

const WhiteSquare = styled.div`
	background: white;
	color: #333;
	padding: 10px;
	border-radius: 15px;
	width: 85%;
	margin: 0 auto;

	.top {
		display: flex;
		justify-content: space-between;
		font-size: 13px;
		margin-bottom: 15px;

		.calendar {
			i {
				margin-right: 5px;
			}
		}

		.current-time {
			color: var(--plenoil-blue);

			i {
				margin-right: 5px;
			}
		}
	}

	.info {
		h2 {
			color: var(--plenoil-blue);
			text-align: center;
			font-size: 20px;
			text-transform: uppercase;
		}

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: lightgray;
			margin: 10px auto;

			i {
				color: white;
				font-size: 40px;
			}
		}

		.salutation {
			text-align: center;
			margin-bottom: 10px;
		}

		margin-bottom: 10px;
	}

	.form {
		width: 90%;
		margin: 0 auto;

		.form-control {
			margin-bottom: 10px;
		}

		button {
			display: flex;
			margin: 0 auto;
		}
	}

	.footer {
        display: flex;
        justify-content: center;
		margin-top: 15px;

		button {

			&:first-child {
				color: #333;
			}

			&:last-child {
				color: var(--plenoil-blue);
			}
		}
    }
`;

const NewPinModal = styled.div`	
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;

	.modal-inner {
		background: white;
		padding: 20px;
		border-radius: 15px;
		width: 80%;

		button {
			display: flex;
			margin: 10px auto;
		}

		label {
			color: #696969;
		}

		.form-control {
			margin-bottom: 5px;

			&.invalid {
				border-color: red;
			}

			&.valid {
				border-color: green;
			}
		}
	}
`;

export default function () {
	let navigate = useNavigate();
	let params = useParams();

    const dispenserId = params.dispenserId;

    let [loading, setLoading] = useState(false);
    let [dispenser, setDispenser] = useState(null);
    let [dni, setDni] = useState(debug ? '73413448R' : '');
    let [pin, setPin] = useState(debug ? '1234' : '');

	let [newPinModalOpened, setNewPinModalOpened] = useState(false);
    let [newPin, setNewPin] = useState('');
    let [newPinConfirm, setNewPinConfirm] = useState('');

	useEffect(() => {
		const getDispensers = async () => {
			setLoading(true);
			
			let dispenser = await ClockingsService.getDispenser(dispenserId);
			setDispenser(dispenser);
			
			setLoading(false);
		};
		getDispensers();

		return function cleanup() {
			ClockingsService.cancel();
		}
	}, [dispenserId]);

	const _logDispenser = (dispenser) => {
		window.sessionStorage.setItem('dispenser', JSON.stringify(dispenser));
		navigate('/clockings/current-schedule/' + dispenser.id);
	}

	const loginDispenser = async () => {
		setLoading(true);
		let result = await ClockingsService.loginDispenser(dispenserId, dni, pin);
		setLoading(false);

		if (result.status) {
			if ( result.first_time ) {
				setNewPinModalOpened(true);
			} else {
				_logDispenser(result.dispenser);
			}
		} else {
			window.sessionStorage.removeItem('dispenser');
			toast.error('Datos no válidos');
		}
	};

	const setDispenserNewPin = async () => {
		setLoading(true);
		let result = await ClockingsService.setDispenserPin(dispenserId, newPin);
		setLoading(false);

		if (result.status) {
			_logDispenser(result.dispenser);
		}
	}

	const isBtnDisabled = dni.replaceAll(' ', '').length === 0 || pin.replaceAll(' ', '').length === 0;
	const newPinValid = newPin.length > 0 && newPin === newPinConfirm;

	return (
		<>
			<LoggedLayout 
				hideFooter={true}
			>
				<Wrapper>
					{ (!loading && dispenser?.id) &&
						<WhiteSquare id="white-square">
							<div className="top">
								<div className="calendar">
									<i className="bi bi-calendar"></i>
									<CurrentDate />
								</div>
								<div className="current-time">
									<i className="bi bi-clock"></i>
									<CurrentTime />
								</div>
							</div>

							<div className="info">
								<h2>Control de turno</h2>
								<div className="icon">
									<i className="bi bi-person"></i>
								</div>
								<div className="salutation">
									Hola, {dispenser?.full_name}
								</div>
							</div>

							<div className="form">
								<input type="text" className="form-control" placeholder="DNI" value={dni} onChange={(e) => setDni(e.target.value)} />
								<input type="password" className="form-control" placeholder="****" value={pin} onChange={(e) => setPin(e.target.value)} />
								<button className="btn btn-sm btn-plenoil-primary" disabled={isBtnDisabled} onClick={loginDispenser}>Entrar</button>
							</div>

							<div className="footer">
								<button className="btn btn-link btn-sm" onClick={() => navigate('/clockings/list')}>Cancelar</button>
							</div>
						</WhiteSquare>
					}
					
					{ loading && loader }
				</Wrapper>
			</LoggedLayout>

			{ newPinModalOpened && 
				<NewPinModal>
					<div className="modal-inner">
						<h2>Establece tu nuevo PIN</h2>

						<label>PIN</label>
						<input type="password" pattern="[0-9]*" inputMode="numeric" className="form-control" placeholder="****" maxLength={4} value={newPin} onChange={(e) => setNewPin(e.target.value)} />
						
						<label>Confirmar PIN</label>
						<input type="password" pattern="[0-9]*" inputMode="numeric" className={'form-control ' + (newPinValid ? 'valid' : 'invalid')} placeholder="****" maxLength={4} value={newPinConfirm} onChange={(e) => setNewPinConfirm(e.target.value)} />
						
						<button className="btn btn-sm btn-plenoil-primary" onClick={setDispenserNewPin} disabled={!newPinValid}>Aceptar</button>
					</div>
				</NewPinModal>
			}
		</>
	);
}


