import axios from 'axios';

export default class Chat {

	static axiosCancelToken = null;
	
	static getDispensers = async () => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/app/dispensers-list', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}
	
	static getDispenser = async (dispenserId) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/app/dispenser/' + dispenserId, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static loginDispenser = async (dispenserId, dni, pin) => {
		this.newAxiosCancelToken();

		return axios.post('api/clockings/app/dispenser/check-dni-pin/' + dispenserId, {
			dni: dni,
			pin: pin
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	} 

	static setDispenserPin = async (dispenserId, pin) => {
		this.newAxiosCancelToken();

		return axios.post('api/clockings/app/dispenser/set-pin/' + dispenserId, {
			pin: pin
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	} 

	static getDispenserCurrentSchedule = async (dispenserId) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/app/dispenser/get-current-schedule/' + dispenserId, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static getDispenserNextSchedule = async (dispenserId) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/app/dispenser/get-next-schedule/' + dispenserId, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static startDispenserChronometer = async (dispenserId) => {
		this.newAxiosCancelToken();

		return axios.post('api/clockings/app/dispenser/start-chronometer/' + dispenserId, {
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static stopDispenserChronometer = async (dispenserId) => {
		this.newAxiosCancelToken();

		return axios.post('api/clockings/app/dispenser/stop-chronometer/' + dispenserId, {
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static getDispenserHistorial = async (dispenserId, page) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/app/dispenser/get-historial/' + dispenserId, {
			params: {
				page: page
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}
	
	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}