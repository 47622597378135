import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components'; 

const AlertDropdownStyled = styled.div`
	display: inline-block;

	.dropdown {
		
		& > i.bi {
			color: var(--plenoil-red);
		}

		.dropdown-menu {
			padding: 0;
			border-color: transparent;
			box-shadow: 0px 0px 10px rgba(1,1,1,0.4);
			z-index: 9999;
			right: 100%;
			top: 0%;
		}
	}
`;

const DropdownMenuInner = styled.div`
	padding: 5px;

	div {
		display: flex;
		width: 100%;
		font-size: 14px;

		&:not(:last-of-type) {
			margin-bottom: 3px;
		}

		i {
			width: 20px;
			margin-right: 5px;
		}
	}
`;

export default function (props) {
	const schedule = props.schedule;

	const element = useRef(null);

	const [opened, setOpened] = useState(false);
 
	const toggleDropdown = () => {
		setOpened(opened ? false : true);
	}

	useEffect(() => {
		let clickEventListener = (e) => {
			if ( element?.current === e.target || element?.current?.contains(e.target) ) {
				return;
			}
			setOpened(false);
		};

		document.addEventListener('click', clickEventListener);

		return function cleanup() {
			document.removeEventListener('click', clickEventListener);
		}
	}, []);

	return (
		<AlertDropdownStyled className="alert-dropdown" ref={element}>
			<div className="dropdown d-inline-block ms-auto">
				<i className="bi bi-circle-fill" onClick={toggleDropdown}></i>
				<div className={'dropdown-menu ' + (opened ? 'show ' : '')} onClick={(e) => e.stopPropagation()}>
					<DropdownMenuInner>
						<div><i className="bi bi-calendar"></i>{moment(schedule.day).format('DD/MM/YYYY')}</div>
						<div><i className="bi bi-clock"></i>{moment('2024-12-03 ' + schedule.start_at).format('HH:mm')}</div>
						<div><i className="bi bi-clock"></i>{moment('2024-12-03 ' + schedule.end_at).format('HH:mm')}</div>
					</DropdownMenuInner>
				</div>
			</div>
		</AlertDropdownStyled>
	);
}