import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import resizeImage from 'resize-image';
import { useNavigate, useParams }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as PricesService } from 'services/Prices';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;

	h2, h3 {
		display: block;
		text-align: center;
		font-size: 20px;
		letter-spacing: -1px;
		margin: 0;
	}

	h3 {
		font-size: 25px;
		margin-top: 20px;
	}

	hr {
		margin: 0;
		padding: 0;
		border-color: var(--bs-body-color);
		opacity: 1;
	}

	.title {
		margin-bottom: 20px;
	}

	input.valid {
		color: green;
		border: 1px solid green !important;
		outline: none !important;
	}

	input.invalid {
		color: red;
		border: 1px solid red !important;
		outline: none !important;
	}
`;

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 8px;
	left: 30px;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-black);
	border-radius: 50%;
	font-size: 20px;
	z-index: 3;
`;

const OilsWrapper = styled.div` 
	.oil_row {
		padding: 10px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;

		&.goa {
			background: var(--plenoil-black);
		}

		&.sp95 {
			background: var(--plenoil-green);
		}

		span {
			width: 50px;
			text-align: right;
			padding-right: 10px;
		}

		input {
			width: 100px;
			border: 0;
			border-radius: 8px;
			text-align: center;
			font-size: 20px;
		}
	}
`;

const CameraButton = styled.button` 
	color: ${props => props.completed ? 'white' : 'var(--plenoil-yellow)'};
	background: ${props => props.completed ? 'var(--plenoil-green)' : 'var(--plenoil-blue)'};
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border: 0;
	font-size: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 30px auto;
`;

const SendButton = styled.button`
	width: 100px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	border: none;
	margin: 20px auto;
	margin-top: 50px;
	padding: 8px 10px;
	border-radius: 10px;

	&:active,
	&:focus,
	&:hover {
		background: var(--plenoil-blue-light);
	}

	span {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: var(--plenoil-blue);
		color: var(--plenoil-yellow);
		border-radius: 50%;
		width: 25px;
		height: 25px;
		font-size: 25px;
		margin-right: 5px;

		i {
			margin-top: 5px;
		}
	}
`;

const ConfirmButton = styled.button`
	width: 40px;
	height: 40px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	border: none;
	border-radius: 50%;

	&:active,
	&:focus,
	&:hover {
		background: var(--plenoil-blue-light);
	}

	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--plenoil-blue);
	color: var(--plenoil-yellow);
	border-radius: 50%;
	width: 25px;
	height: 25px;
	font-size: 25px;

	i {
		margin-top: 5px;
	}

	margin-left: 10px;
`;

let timerSeconds = 10;
let goaTimerInterval = null;
let sp95TimerInterval = null;

export default function PricesControl() {
	let navigate = useNavigate();
	let params = useParams();

	const inputFileRef = useRef(null);

	let stationCompetitionId = params.id;

	let [currentInterval, setCurrentInterval] = useState(null);
	let [selectedStation, setSelectedStation] = useState(null);

	let [goaTimer, setGoaTimer] = useState(timerSeconds);
	let [sp95Timer, setSp95Timer] = useState(timerSeconds);

	let [goaPrice, _setGoaPrice] = useState(null);
	let [goaPrice2, _setGoaPrice2] = useState(null);
	let setGoaPrice2 = (value) => {
		setGoaValid(parseFloat(goaPrice) === parseFloat(value));
		_setGoaPrice2(value);
	}
	let [goaValid, setGoaValid] = useState(false);
	let goaPriceRef = useRef(null);
	let setGoaPrice = (value) => {
		_setGoaPrice(value);
		goaPriceRef.current = value;
	}
	let initGoaTimer = () => {
		if ( goaTimer !== timerSeconds ) return;
		
		setGoaTimer(timerSeconds-1);
		goaTimerInterval = setInterval(() => {
			setGoaTimer((state) => {
				let timer = state-1;
				if ( timer <= 0 ) clearInterval(goaTimerInterval);
				return timer;
			});
		}, 1000);
	}

	let [sp95Price, _setSp95Price] = useState(null);
	let [sp95Price2, _setSp95Price2] = useState(null);
	let setSp95Price2 = (value) => {
		setSp95Valid(parseFloat(sp95Price) === parseFloat(value));
		_setSp95Price2(value);
	}
	let [sp95Valid, setSp95Valid] = useState(false);
	let sp95PriceRef = useRef(null);
	let setSp95Price = (value) => {
		_setSp95Price(value);
		sp95PriceRef.current = value;
	}
	let initSp95Timer = () => {
		if ( sp95Timer !== timerSeconds ) return;
		
		setSp95Timer(timerSeconds-1);
		sp95TimerInterval = setInterval(() => {
			setSp95Timer((state) => {
				let timer = state-1;
				if ( timer <= 0 ) clearInterval(sp95TimerInterval);
				return timer;
			});
		}, 1000);
	}

	let [photo, setPhoto] = useState(null);

	useEffect(() => {		
		const getData = async () => {
			// Get current interval
			let intervals = await PricesService.getCompetitionIntervalList();
			if ( !intervals ) return;
			
			let current = intervals.filter(el => el.current === true)[0] ?? null; 
			if ( !current ) {
				navigate(-1);
				return;
			}
			setCurrentInterval(current);

			// Get selected station
			let stations = await PricesService.getCompetitionStationsList(intervals.filter(el => el.current === true)[0]?.id ?? null, true, stationCompetitionId);
			let station = stations ? stations[0] ?? null : null;
			if ( !station /*|| station.visited*/ ) {
				navigate(-1);
				return;
			} else {
				setSelectedStation(station);

				// Set prices if not prices before
				// if (!goaPriceRef.current) setGoaPrice(station.goa_price);
				// if (!sp95PriceRef.current) setSp95Price(station.sp95_price);
			}
		};

		let getDataInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			clearInterval(getDataInterval);
			PricesService.cancel();
		}
	}, [navigate, stationCompetitionId]);

	const takePicture = async () => {
		// const image = await Camera.getPhoto({
		// 	quality: 100,
		// 	allowEditing: false,
		// 	resultType: CameraResultType.DataUrl,
		// 	source: CameraSource.Camera
		// });
		
		inputFileRef.current.value = null;
		inputFileRef.current.click();

		// Change size to image
		// let img = new Image();
		// img.onload = async () => {
		// 	let data = resizeImage.resize(img, 2048, null, resizeImage.JPEG);

		// 	// Convert to blob
		// 	let blob = await fetch(data).then(res => res.blob());
			
		// 	setPhoto(blob);
		// }
		// img.src = image.dataUrl;
	};

	const loadImage = async (event) => {
        let file = event.target.files[0];

        // Check extension
        let nameSplit = file.name.split('.');
        let extension = nameSplit.pop();
        if ( ['jpg', 'jpeg', 'png', 'bmp', 'gif'].indexOf(extension) === -1 ) {
            toast.error('El fichero de imágen tiene un formato inválido');
            return;
        }

        // Read file
        var reader = new FileReader();
        reader.onload = () => {            
            var dataUrl = reader.result;
            
            // Change size to image
            let img = new Image();
            img.onload = async () => {
                let data = resizeImage.resize(img, 1410, null, resizeImage.JPEG);

                // Convert to blob
                let blob = await fetch(data).then(res => res.blob());
                
                setPhoto(blob);
            }
            img.src = dataUrl;
        };
        reader.readAsDataURL(file);
    }

	const saveData = async () => {
		let result = await PricesService.saveCompetitionStationVisit(currentInterval.id, selectedStation.id, goaPrice, sp95Price, photo);
		if ( !result.status ) {
			toast.error('Ha ocurrido un error, por favor inténtelo de nuevo');
		}
		navigate(-1);
	}

	return (
		<LoggedLayout hideFooter={true} hideHeader={false}>
			<BackButton onClick={() => navigate(-1)}>
				<i className="bi bi-chevron-left"></i>
			</BackButton>

			<Wrapper>
				<div className="title">
					<h2>CONTROL DE PRECIOS</h2>
					<hr />
					<h3>{selectedStation?.name}</h3>
				</div>

				<OilsWrapper>
					<div className="oil_row goa">
						<span>DIESEL</span>
						{goaTimer === timerSeconds && 
							<>
								<input 
									type="number" 
									className="no-arrows" 
									step="0.001" 
									value={goaPrice ?? ''} 
									onChange={(e) => setGoaPrice(e.target.value)} 
									onFocus={(e) => e.target.select()} 
								/>
								<ConfirmButton onClick={() => initGoaTimer()}><i className="bi bi-check"></i></ConfirmButton>
							</>
						}
						{(goaTimer !== 0 && goaTimer < timerSeconds) && 
							<>
								<i className="bi bi-clock ms-2"></i>
								{goaTimer}
							</>
						}
						{goaTimer === 0 &&
							<div className="ms-3">
								<input 
									type="number" 
									className={'no-arrows me-2 ' + (goaValid ? 'valid' : 'invalid')} 
									step="0.001"
									value={goaPrice2 ?? ''} 
									onChange={(e) => setGoaPrice2(e.target.value)} 
									onFocus={(e) => e.target.select()} 
								/>
								{!goaValid ? 'REPETIR' : 'OK'}
							</div>
						}
					</div>
					<div className="oil_row sp95">
						<span>SP</span>
						{sp95Timer === timerSeconds && 
							<>
								<input 
									type="number" 
									className="no-arrows" 
									step="0.001" 
									value={sp95Price ?? ''} 
									onChange={(e) => setSp95Price(e.target.value)} 
									onFocus={(e) => e.target.select()} 
								/>
								<ConfirmButton onClick={() => initSp95Timer()}><i className="bi bi-check"></i></ConfirmButton>
							</>
						}
						{(sp95Timer !== 0 && sp95Timer < timerSeconds) && 
							<>
								<i className="bi bi-clock ms-2"></i>
								{sp95Timer}
							</>
						}
						{sp95Timer === 0 &&
							<div className="ms-3">
								<input 
									type="number" 
									className={'no-arrows me-2 ' + (sp95Valid ? 'valid' : 'invalid')} 
									step="0.001"
									value={sp95Price2 ?? ''} 
									onChange={(e) => setSp95Price2(e.target.value)} 
									onFocus={(e) => e.target.select()} 
								/>
								{!sp95Valid ? 'REPETIR' : 'OK'}
							</div>
						}
					</div>
				</OilsWrapper>

				<CameraButton onClick={() => takePicture()} completed={photo ? true : false}><i className="bi bi-camera-fill"></i></CameraButton>

				<SendButton onClick={() => saveData()} disabled={!goaValid || !sp95Valid}><span><i className="bi bi-check"></i></span> ENVIAR</SendButton>

				<input type="file" ref={inputFileRef} className="d-none" onChange={loadImage} />
			</Wrapper>
		</LoggedLayout>
	);
}


