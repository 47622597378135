import styled from 'styled-components';

const AlertStyled = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    color: white;
    background: ${props => props.color};
    border-radius: 50%;
    font-size: 12px;
`;

export default (props) => {
    let type = props.type;
    let parpadeo = props.parpadeo;

    let color = '';
    if ( type === 'imminent_appointment' ) color = 'var(--plenoil-green)';
    if ( type === 'active_appointment' ) color = 'var(--plenoil-blue)';
    if ( type === 'not_started' ) color = 'var(--plenoil-red)';
    if ( type === 'early_leave' ) color = 'var(--plenoil-red)';

    let icon = 'bi bi-bell-fill';
    if ( type === 'not_started' ) icon = 'bi-exclamation-triangle';
    if ( type === 'early_leave' ) icon = 'bi-exclamation-triangle';

    return (
        <AlertStyled color={color} className={'alert-circle ' + (parpadeo ? 'parpadeo' : '')}>
            <i className={icon}></i>
        </AlertStyled>
    );
}