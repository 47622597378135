import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { loader } from 'helpers/generic';
import { default as ClockingsService } from 'services/Clockings';
import AlertCircle from './AlertCircle';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;

	button {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid white;
		color: white;
		background: var(--plenoil-blue);
		border-radius: 50%;
		font-size: 15px;
		margin-bottom: 20px;

		i {
			margin-left: -1px;
			margin-bottom: -1px;
		}
	}

	.dispenser-row {
		padding: 10px;
		border: 1px solid var(--plenoil-blue);
		border-radius: 20px;
		margin-bottom: 10px;
		width: 80%;
		color: #333;
		cursor: pointer;
		user-select: none;
		position: relative;

		&:hover {
			border-color: var(--plenoil-red);
		}

		.icon {
			display: inline-flex;
			color: white;
			background: gray;
			width: 30px;
			aspect-ratio: 1;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			margin-right: 10px;
		}

		.alert-circle {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%) translateX(50%);
		}
	}
`;

export default function () {
	let navigate = useNavigate();

	let intervalRef = useRef(null);

    let [loading, setLoading] = useState(false);
    let [dispensers, setDispensers] = useState([]);

	const getDispensers = useCallback(async () => {
		let data = await ClockingsService.getDispensers();
		setDispensers(data);
	}, []);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			
			await getDispensers();
			intervalRef.current = setInterval(getDispensers, 5000);
			
			setLoading(false);
		};
		getData();

		return function cleanup() {
			if(intervalRef.current) clearInterval(intervalRef.current);
			ClockingsService.cancel();
		}
	}, [getDispensers]);

	return (
		<LoggedLayout 
			hideFooter={true}
		>
			<Wrapper>
				<button onClick={() => navigate('/')}>
					<i className="bi bi-chevron-left"></i>
				</button>

				{dispensers.map((dispenser, index) => {
					let type = false;
					Object.keys(dispenser.alerts).forEach(key => {
						if ( dispenser.alerts[key] ) {
							type = key;
						}
					});
				
					return (
						<div key={index} className="dispenser-row" onClick={() => navigate('/clockings/pin/' + dispenser.id)}>
							<span className="icon">
								<i className="bi bi-person"></i>
							</span>
							
							{dispenser.full_name}

							{type && <AlertCircle type={type} parpadeo={type === 'not_started' || type === 'early_leave'} />}
						</div>
					);
				})}
				{ loading && <div>{loader}</div> }
			</Wrapper>
		</LoggedLayout>
	);
}


