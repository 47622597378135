import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { loader } from 'helpers/generic';
import { default as ClockingsService } from 'services/Clockings';
import AlertDropdown from './AlertDropdown';

moment.locale('es');

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
`;

const HistorialWrapper = styled.div`
	width: 95%;
	overflow: auto;

	.dispenser {
		display: flex;
		align-items: center;
		justify-content: start;
		margin-bottom: 20px;

		.icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: 40px;
			min-height: 40px;
			aspect-ratio: 1;
			border-radius: 50%;
			background-color: lightgray;
			margin-right: 10px;

			i {
				color: white;
				font-size: 30px;
			}
		}

		.name {
			width: 100%;
			text-transform: uppercase;
			color: #333;
		}
	}

	table {

		thead {
			background: transparent;
			
			tr {
				background: transparent;

				th {
					background: transparent;
					text-align: center;

					&:nth-child(1) {
						color: gray;
						width: 50px;
					}

					&:nth-child(2) {
						color: var(--plenoil-blue);
						width: 90px;
					}

					&:nth-child(3) {
						color: var(--plenoil-orange);
						width: 90px;
					}

					&:nth-child(4) {
						width: 40px;
					}
				}
			}
		}

		tbody {
			background: white;

			tr {

				td {
					text-align: center;
					vertical-align: middle;

					&.gray {
						color: lightgray;
					}
				}

				&.page-tr {
					
					td {
						line-height: 0;
						height: 0;
						padding: 0;
						visibility: hidden;
					}
				}

				&.month-tr {

					td {
						background: var(--plenoil-blue);
						color: white;
						text-transform: uppercase;
					}
				}
			}
		}
	}

	#loader {
		width: 20px;
	}
`;

export default function () {
	let navigate = useNavigate();

	const pageTrRef = useRef(null);
	const currentPageRef = useRef(1);

	const [loading, setLoading] = useState(false);
	const [dispenser, setDispenser] = useState(null);
	const [historial, setHistorial] = useState([]);
	const [isPageTrRendered, setIsPageTrRendered] = useState(false);
	const [showNextPageTr, setShowNextPageTr] = useState(true);
	const [page, _setPage] = useState(1);
	const setPage = (page) => {
		currentPageRef.current = page;
		_setPage(page);
	}

	useEffect(() => {
		let dispenser = window.sessionStorage.getItem('dispenser');
		if ( dispenser ) dispenser = JSON.parse(dispenser);
		if ( !dispenser ) {
			navigate(-1);
		} else {
			setDispenser(dispenser);
		}
	}, [navigate])

	useEffect(() => {
		if ( !dispenser ) return;

		const getData = async () => {
			setLoading(true);
			
			let historial = await ClockingsService.getDispenserHistorial(dispenser.id, page);
			if ( historial.length > 0 ) {
				setHistorial(prev => [...prev, ...historial]);
			} else {
				setShowNextPageTr(false);
			}
			
			setLoading(false);
		};
		getData();

		return function cleanup() {
			ClockingsService.cancel();
		}
	}, [dispenser, page]);

	useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setPage(currentPageRef.current+1)
                    }
                });
            },
            {
                root: null, 
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        if (pageTrRef.current) {
            observer.observe(pageTrRef.current);
        }

        return () => {
            if (pageTrRef.current) {
                observer.unobserve(pageTrRef.current);
            }
        };
    }, [isPageTrRendered]);

	useEffect(() => {
        if (pageTrRef.current) {
            setIsPageTrRendered(Math.random());
        }
    }, [historial]);

	return (
		<LoggedLayout 
			hideFooter={true}
		>
			<Wrapper>
				<HistorialWrapper>
					<div className="dispenser">
						<span className="icon">
							<i className="bi bi-person"></i>
						</span>
						<div className="name">
							{dispenser?.full_name}
						</div>
					</div>


					{ historial.length > 0 &&
						<table className="table table-sm">
							<thead>
								<tr>
									<th><i className="bi bi-calendar"></i></th>
									<th><i className="bi bi-clock"></i></th>
									<th><i className="bi bi-clock"></i></th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{historial.map((item, index) => {
									let yearMonth = moment(item.day).format('YYYY-MM');

									let printMonthName = false;
									let prevItem = historial[index-1] ?? null;
									if ( !prevItem || moment(prevItem.day).format('YYYY-MM') !== yearMonth ) {
										printMonthName = true;
									}

									let row = 
										<React.Fragment key={index}>
											{printMonthName &&
												<tr className="month-tr">
													<td colSpan="100%">
														{moment(item.day).format('MMMM YYYY')}
													</td>
												</tr>
											}
											<tr>
												<td>{moment(item.day).format('DD')}</td>
												<td className={!item.real_start_at ? 'gray' : ''}>{moment('2024-11-29 ' + (item.real_start_at ?? item.start_at)).format('HH:mm')}</td>
												<td className={!item.real_end_at ? 'gray' : ''}>{moment('2024-11-29 ' + (item.real_end_at ?? item.end_at)).format('HH:mm')}</td>
												<td>
													{ item.has_alerts &&
														<AlertDropdown 
															schedule={item}
														/>
													}
												</td>
												<td></td>
											</tr>
											{(index === historial.length-1 && showNextPageTr) &&
												<tr ref={pageTrRef} className="page-tr" data-page={page+1}><td colSpan="100%">{page+1}</td></tr>										
											}
										</React.Fragment>
									;
									return row;
								})}
							</tbody>
						</table>
					}
					{loading && loader}
				</HistorialWrapper>
			</Wrapper>
		</LoggedLayout>
	);
}


