import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledLayer = styled.div`
    display: inline-flex;
`;

export default function (props) {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const hour = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');

    return (
        <StyledLayer>
            <div className="hour">{hour}</div>:
            <div className="minutes">{minutes}</div>
            { !props.hideSeconds &&            
                <>
                    :<div className="seconds">{seconds}</div>
                </>
            }
        </StyledLayer>
    );
}