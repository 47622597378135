import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ActiveDispensersStyled = styled.div` 
	display: flex;
	align-items: center;

	.dispensers-wrapper {
		text-align: right;

		span {
			all: unset;
			font-size: 13px !important;
		}
	}

	.icon {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid white;
		background: var(--plenoil-blue);
		border-radius: 50%;
		font-size: 12px;
		margin-right: 5px;

		i {
			color: white;
		}
	}
`;

const ActiveDispensers = () => {
	const navigate = useNavigate();

	let handleReloadActiveDispensersRef = useRef(null);

	let [forceReload, setForceReload] = useState(null);
	let [dispensers, setDispensers] = useState([]);

	useEffect(() => {
		if (handleReloadActiveDispensersRef.current) return;

		handleReloadActiveDispensersRef.current = () => {
			setForceReload(Math.random());
		}

		document.addEventListener('reload-active-dispensers', handleReloadActiveDispensersRef.current);
	}, [])

	useEffect(() => {
		let dispensers = window.sessionStorage.getItem('dispensers');
		if ( dispensers ) {
			dispensers = JSON.parse(dispensers);
			setDispensers([]);
			setDispensers(dispensers);
		}
	}, [forceReload]);

	return (
		<>
			{dispensers.length > 0 && 
				<ActiveDispensersStyled onClick={() => navigate('/clockings/list')}>
					<div className="dispensers-wrapper">
						{dispensers.map((dispenser, index) => (
							<div key={index}>
								<span>{dispenser.full_name}</span>
							</div>
						))}
					</div>
					<div className="icon">
						<i className="bi bi-bell-fill"></i>
					</div>
				</ActiveDispensersStyled>
			}
		</>
	);
}

export default ActiveDispensers;