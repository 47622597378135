import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AlertCircle from 'pages/ClockingList/AlertCircle';
import Clockings from 'services/Clockings';

const StyledElement = styled.div`
	position: absolute;
	right: 0;
	transform: translateX(50%);
`;

export default function UsersClockingIntervalChecker() {
	let [inminentAppoinment, setInminentAppoinment] = useState(false);
	let [activeAppointment, setActiveAppointment] = useState(false);
	let [notStarted, setNotStarted] = useState(false);
	let [earlyLeave, setEarlyLeave] = useState(false);

	useEffect(() => {
		const getDispensers = async () => {
			let dispensers = await Clockings.getDispensers();
			
			setInminentAppoinment(false);
			setActiveAppointment(false);
			setNotStarted(false);
			setEarlyLeave(false);

			if (dispensers) {
				dispensers.forEach(dispenser => {
					Object.keys(dispenser.alerts).forEach(key => {
						if (dispenser.alerts[key]) {
							if (key === 'imminent_appointment') {
								setInminentAppoinment(true);
							}
							if (key === 'active_appointment') {
								setActiveAppointment(true);
							}
							if (key === 'not_started') {
								setNotStarted(true);
							}
							if (key === 'early_leave') {
								setEarlyLeave(true);
							}
						}
					});
				});
			}
		};

		let getDispensersInterval = setInterval(() => {
			getDispensers();
		}, 5000);
		getDispensers();

		return function cleanup() {
			clearInterval(getDispensersInterval);
			Clockings.cancel();
		}
	}, []);

	return (
		<StyledElement>
			{inminentAppoinment && <AlertCircle type={'imminent_appointment'} parpadeo={false} />}
			{activeAppointment && <AlertCircle type={'active_appointment'} parpadeo={false} />}
			{(notStarted || earlyLeave) && <AlertCircle type={'not_started'} parpadeo={true} />}
		</StyledElement>
	);
}


