import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledLayer = styled.div`
    display: inline-flex;
    color: #333;
`;

export default function (props) {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return (
        <StyledLayer>
            <div className="day">{day}</div>/
            <div className="month">{month}</div>/
            <div className="year">{year}</div>
        </StyledLayer>
    );
}